<template>
  <v-card outlined>
    <v-card-title class="justify-center">
      Account Verification
    </v-card-title>
    <v-card-text class="text-center">
      <p>Ethereum account verification status.</p>

      <v-icon :color="statuses[status].color" size="80px">
        {{ statuses[status].icon }}
      </v-icon>

      <p>{{ statuses[status].name }}</p>

      <wallet v-if="!account" small></wallet>

      <p v-if="account">
        <account :account="account" class="me-3"></account>
      </p>

      <v-btn
        v-if="account && status === 'unverified'"
        color="secondary"
        :loading="loading"
        outlined
        rounded
        small
        @click="sign"
      >
        Verify Now
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiShieldOff, mdiShieldCheck } from '@mdi/js'
import axios from 'axios'
import Account from '@/components/wallet/Account.vue'
import Wallet from '@/components/wallet/Wallet.vue'

export default {
  components: {
    Account,
    Wallet,
  },
  data() {
    return {
      statuses: {
        unverified: {
          name: 'Unverified',
          color: '#607D8B',
          icon: mdiShieldOff,
        },
        verified: {
          name: 'Verified',
          color: '#4CAF50',
          icon: mdiShieldCheck,
        },
      },
      loading: false,
    }
  },
  computed: {
    account() {
      var account = this.$store.getters.wallet
      if (!account) {
        var app_metadata = this.$auth.user['https://namespace/app_metadata'] || {}
        var eth_accounts = app_metadata.eth_accounts || []
        account = eth_accounts[0]
      }
      return account
    },
    status() {
      var app_metadata = this.$auth.user['https://namespace/app_metadata'] || {}
      var eth_accounts = app_metadata.eth_accounts || []
      return eth_accounts.includes(this.account) ? 'verified' : 'unverified'
    },
  },
  methods: {
    sign() {
      this.loading = true

      var nonce = Math.floor(Math.random() * 1000000)
      var data = this.$web3.utils.utf8ToHex(`Click "Sign" to verify your account.\n\nThis will not trigger a blockchain transaction or cost any gas fees.\n\nNonce: ${nonce}`)

      this.$web3.eth.personal.sign(
        data,
        this.account,
        (err, signature) => {
          if (err) {
            this.loading = false
          } else {
            this.verifyAccount(this.account, data, signature)
          }
        },
      )
    },
    async verifyAccount(account, data, signature, cb) {
      var payload = {
        account,
        data,
        signature,
      }

      var accessToken = await this.$auth.getTokenSilently()

      axios.post(process.env.VUE_APP_API + '/user/ethereum-verify', payload, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }).then(async (response) => {
        await this.$auth.getTokenSilently({ ignoreCache: true })
        this.$auth.user['https://namespace/app_metadata'] = response.data.app_metadata
        this.loading = false
      }).catch((err) => {
        this.loading = false
      })
    },
  },
}
</script>
