<template>
  <div>
    <h1 class="mb-4">
      Verification
    </h1>
    <p class="mb-6">
      Verify your identity and blockchain account.
    </p>
    <v-row>
      <v-col cols="12" sm="6">
        <blockpass></blockpass>
      </v-col>
      <v-col cols="12" sm="6">
        <ethereum></ethereum>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Blockpass from './Blockpass.vue'
import Ethereum from './Ethereum.vue'

export default {
  components: {
    Blockpass,
    Ethereum,
  },
}
</script>
