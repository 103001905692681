<template>
  <v-card :loading="loading" outlined>
    <v-card-title class="justify-center">
      Identity Verification
    </v-card-title>
    <v-card-text class="text-center">
      <p>Blockpass identity verification status.</p>

      <v-icon :color="statuses[status].color" size="80px">
        {{ statuses[status].icon }}
      </v-icon>

      <p>{{ statuses[status].name }}</p>

      <p v-if="identity">
        <v-chip>
          <v-icon color="#2196F3" size="22px" left>
            {{ icons.mdiAccountCircle }}
          </v-icon>
          {{ identity }}
        </v-chip>
      </p>

      <v-btn
        v-if="status === 'unverified' || status === 'rejected'"
        id="blockpass-kyc-connect"
        color="secondary"
        :loading="loadingWidget"
        outlined
        rounded
        small
        @click="openWidget"
      >
        Verify Now
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiShieldOff, mdiShieldAccount, mdiShieldSearch, mdiShieldCheck, mdiShieldEdit, mdiAccountCircle } from '@mdi/js'
import axios from 'axios'

export default {
  setup() {
    return {
      icons: {
        mdiAccountCircle,
      },
    }
  },
  data() {
    return {
      statuses: {
        unverified: {
          name: 'Unverified',
          color: '#607D8B',
          icon: mdiShieldOff,
        },
        incomplete: {
          name: 'Submitted',
          color: '#2196F3',
          icon: mdiShieldAccount,
        },
        waiting: {
          name: 'Submitted',
          color: '#2196F3',
          icon: mdiShieldAccount,
        },
        inreview: {
          name: 'In review',
          color: '#2196F3',
          icon: mdiShieldSearch,
        },
        approved: {
          name: 'Verified',
          color: '#4CAF50',
          icon: mdiShieldCheck,
        },
        rejected: {
          name: 'Clarification required',
          color: '#FFC107',
          icon: mdiShieldEdit,
        },
      },
      loading: false,
      loadingWidget: false,
    }
  },
  computed: {
    identity() {
      var app_metadata = this.$auth.user['https://namespace/app_metadata'] || {}
      var identity
      if (app_metadata.blockpass_given_name && app_metadata.blockpass_family_name && app_metadata.blockpass_email) {
        identity = `${app_metadata.blockpass_given_name} ${app_metadata.blockpass_family_name} <${app_metadata.blockpass_email}>`
      }
      return identity
    },
    status() {
      var app_metadata = this.$auth.user['https://namespace/app_metadata'] || {}
      var status = app_metadata.blockpass_status || 'unverified'
      return status
    },
  },
  watch: {
    status: function(status) {
      if (status === 'unverified' || status === 'rejected') {
        this.initWidget()
      }
    },
  },
  mounted() {
    if (this.status !== 'approved') {
      this.getStatus()
      this.initWidget()
    }
  },
  methods: {
    async getStatus() {
      this.loading = true

      var accessToken = await this.$auth.getTokenSilently()

      axios.post(process.env.VUE_APP_API + '/user/blockpass-status', {}, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }).then(async (response) => {
        await this.$auth.getTokenSilently({ ignoreCache: true })
        this.$auth.user['https://namespace/app_metadata'] = response.data.app_metadata
        this.loading = false
      }).catch((err) => {
        this.loading = false
      })
    },
    openWidget() {
      this.loadingWidget = true
    },
    initWidget() {
      this.$loadScript('https://cdn.blockpass.org/widget/scripts/release/3.0.0/blockpass-kyc-connect.prod.js').then(() => {
        var blockpass = new window.BlockpassKYCConnect(
          process.env.VUE_APP_BLOCKPASS_CLIENT_ID,
          {
            env: 'prod',
            refId: this.$auth.user.sub.replace('|', '-'),
            mainColor: '123850',
            email: this.$auth.user.email,
          },
        )

        blockpass.startKYCConnect()

        blockpass.on('KYCConnectSuccess', (x) => {
          this.getStatus()
          this.loadingWidget = false
        })

        blockpass.on('KYCConnectClose', (y) => {
          this.getStatus()
          this.loadingWidget = false
        })

        blockpass.on('KYCConnectCancel', (z) => {
          this.getStatus()
          this.loadingWidget = false
        })
      })
    },
  },
}
</script>
